<template>
  <div class="home_main">
    <div class="home_assets">
      <div class="home_total_assets">
        <div class="home_assets_left">
          <span>总资产</span>
          <i :class="{ show_icon:showAssets === false,hide_icon: showAssets === true}"
             @click="showAssets=!showAssets"></i>
        </div>
        <div class="home_assets_right" @click="conversionShow">
          <i :class="{ hk:currency === 'HKD',usa:currency === 'USD',cn:currency === 'RMB'}"></i>
          <i class="triangle"></i>
        </div>
      </div>
      <div class="home_Exhibition">
        <i class="purse_icon"></i>
        <p>总资产</p>
        <i class="matter_icon" @click="show=!show"></i>
      </div>
      <div class="home_amount">
        <div v-if="showAssets">
          <span>{{this.thousands(totalAssetsNum)}}</span>
          <i>{{currency}}</i>
        </div>
        <div v-else><span>*****</span></div>
      </div>
      <div class="home_profit">
        <dl>
          <dt>最新收益</dt>
          <dd v-if="showAssets"><span>{{ this.thousands(daily_profit) }}</span><i>{{ currency }}</i></dd>
          <dd v-else><span>*****</span></dd>
        </dl>
        <dl>
          <dt>累计收益</dt>
          <dd v-if="showAssets"><span>{{ this.thousands(profit) }}</span><i>{{currency}}</i></dd>
          <dd v-else><span>*****</span></dd>
        </dl>
      </div>
    </div>
    <div class="home_position">
      <div class="position_title"><span>我的持仓</span><i>{{ rate_date }}</i></div>
      <div class="position_list">
        <div class="position_assets_title"><span>资产</span><span>持仓金额</span></div>
        <ul>
          <li v-for="(item,index) in assetsList" :key="index">
            <div class="assets_name">{{ item.asset }}</div>
            <div class="assets_money">
              <p>{{ item.amount }} {{item.currency}}</p>
              <div class="position_profit">
                <span>最新收益</span>
                <i v-if="item.profit_bool === true" style="color: #E02020">+{{ item.new_profit }} {{item.currency}}</i>
                <i v-else-if="item.profit_bool === false" style="color: #67C23A">-{{ item.new_profit }} {{item.currency}}</i>
                <i v-else>{{ item.new_profit }}</i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <van-dialog v-model="conversion" title="汇率换算" show-cancel-button @confirm="confirm" @cancel="cancel">
<!--      <van-picker title="汇率换算" :columns="columns" @change="ExchangeRate"/>-->
      <div class="butList">
        <ul>
          <li v-for="(item,index) in butList" :key="index" :style="{background: active===index ?'#246FDD':'',color:active===index ?'#ffffff':''}" @click="dialogBut(index)">{{item.name}}</li>
        </ul>
        <div class="rate">
          <span>1USD = {{this.formatNum(rateData.USD_RMB)}} RMB</span>
          <span>1HKD = {{this.formatNum(rateData.HKD_RMB)}} RMB</span>
        </div>
      </div>
      <div class="home_conversion">
        <p>汇率换算：根据中国外汇交易中心{{ rateDate }}对外汇率进行换算，具体以市场交易价格为准。</p>
      </div>
    </van-dialog>
    <div class="home_explain">该资产数据仅作为参考，具体以市场交易价格为准</div>
    <van-action-sheet v-model="show" title="总资产" cancel-text="我知道了" width="90%">
      <div class="content">计算各类持仓资产的总和，并根据最新<span style="color: #246FDD; text-decoration: underline">中国外汇交易中心</span>对外汇率进行换算，该资产数据仅作为参考，具体以市场交易价格为准的汇率进行换算
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {assets, assetsTotal, exchangeRate} from "@/api/home";
import storage from "good-storage";
import { TweenMax } from "gsap";

export default {
  name: "home",
  data() {
    return {
      tweenedNumber1: 0, //总资产-初始化数字，使数字归0
      assetsList: [],
      rate_date: '',
      assets: {},
      conversion: false,
      currency: 'RMB',
      currency_transfer: '美元计价',
      showAssets: true,
      rateDate: '',
      rateData: {},
      show: false,
      active: 0,
      butList: [
          {name:'人民币计价',currency: 'RMB'},
          {name:'美元计价',currency: 'USD'},
          {name:'港元计价',currency: 'HKD'},
      ],
      amount: '',
      daily_profit: '',
      profit: ''
    }
  },
  mounted() {
    this.GetAssetsTotal()
    this.GetAssets()
  },
  computed:{
    totalAssetsNum() {
      return this.tweenedNumber1.toFixed(2);
    }
  },
  methods: {
     dialogBut(index){
      this.active = index
      this.currency_transfer = this.butList[index].name
    },
    thousands(str) {
      //str 字符类型的数字
      let thousandsReg = /(\d)(?=(\d{3})+$)/g;
      const numArr = str.split(".");
      numArr[0] = numArr[0].replace(thousandsReg, "$1,");
      return numArr.join(".");
    },
    // 获取持仓列表
    GetAssets() {
      const phone = this.$store.state.user.phone
      assets({phone: phone}).then(res => {
        let list = res.data.my_position
        for (let i = 0; i < list.length; i++) {
          list[i].amount = this.thousands((list[i].amount).toString())
          list[i].new_profit = this.thousands((list[i].new_profit).toString())
        }
        this.assetsList = list
        this.rate_date = res.data.sync_time
      })
    },
    // 获取资产
    GetAssetsTotal() {
      const id = storage.get('userId')
      const info = {
        customer_id: id,
      }
      assetsTotal(info).then(res => {
        this.assets = res.data
        // this.amount = res.data.amount[this.currency]
        this.DigitalLoad(Number(res.data.amount[this.currency]), "tweenedNumber1");
        this.daily_profit = res.data.daily_profit[this.currency]
        this.profit = res.data.profit[this.currency]
      })
    },
    //数字加载方法
    DigitalLoad(value, numType) {
      // 1 为变化时间
      TweenMax.to(this.$data, 1, { [numType]: value });
    },
    conversionShow() {
      this.GetExchangeRate()
      this.conversion = true
    },
    formatNum(num) {
      //1. 可能是字符串，转换为浮点数
      //2. 乘以100 小数点向右移动两位
      //3. Math.round 进行四舍五入
      //4. 除以100 小数点向左移动两位 实现保留小数点后两位
      let value = Math.round(parseFloat(num) * 10000) / 10000;
      // // 去掉小数点 存为数组
      let arrayNum = value.toString().split(".");
      // //只有一位（整数）
      if (arrayNum.length == 1) {
        return value.toString() + ".0000";
      }
      if (arrayNum.length > 1) {
        //小数点右侧 如果小于两位 则补一个0
        if (arrayNum[1].length < 2) {
          return value.toString() + "000";
        }else if (arrayNum[1].length < 3){
          return value.toString() + "00";
        }else if (arrayNum[1].length < 4){
          return value.toString() + "0";
        }
        return value;
      }

    },
    // 获取汇率
    GetExchangeRate() {
      exchangeRate().then(res => {
        this.rateDate = res.data.date
        this.rateData = res.data
      })
    },
    confirm() {
      switch (this.currency_transfer) {
        case '美元计价':
          this.currency = 'USD';
          break
        case '港元计价':
          this.currency = 'HKD';
          break
        case '人民币计价':
          this.currency = 'RMB';
          break
      }
      this.GetAssetsTotal()
      this.conversion = false
      // this.$refs.myNum.start()
    },
    cancel() {
      this.conversion = false
    }
  }
}
</script>

<style scoped lang="scss">
.home_main {
  //background: #F2F3F5;
  //min-height: 100vh;
  padding-bottom: 62px;

  .home_assets {
    width: 100%;
    height: 190px;
    background: linear-gradient(180deg, #3E7EFF 0%, #1147DE 100%);
    margin-bottom: 12px;

    .home_total_assets {
      height: 36px;
      box-sizing: border-box;
      padding: 0 9px;
      border-bottom: 1px solid #578EFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;

      .home_assets_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 36px;
          margin-right: 5px;
        }

        .hide_icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          background-image: url("../../assets/images/mobile_hide.svg");
          background-repeat: no-repeat;
          background-size: 100%;
        }

        .show_icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          background-image: url("../../assets/images/mobile_show.svg");
          background-repeat: no-repeat;
          background-size: 120%;
        }
      }

      .home_assets_right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .usa {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("../../assets/images/mobile_usa_icon.svg");
          background-size: 100%;
          margin-right: 5px;
        }

        .hk {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("../../assets/images/mobile_hk_icon.svg");
          background-size: 100%;
          margin-right: 5px;
          background-position: 0 5px;
        }

        .cn {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("../../assets/images/mobile_cn_icon.svg");
          background-size: 100%;
          margin-right: 5px;
          background-position: 0 5px;
        }

        .triangle {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100%;
          background-image: url("../../assets/images/mobile_down_triangle.svg");
          margin-right: 5px;
        }
      }

    }

    .home_Exhibition {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
      }

      .purse_icon {
        width: 15px;
        height: 15px;
        background-image: url("../../assets/images/mobile_purse.svg");
      }

      p {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 18px;
        margin: 0 5px;
      }

      .matter_icon {
        width: 12px;
        height: 12px;
        background-image: url("../../assets/images/mobile_matter.svg");
      }
    }

    .home_amount {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 18px;
      margin-bottom: 19px;

      i {
        height: 13px;
        font-size: 13px !important;
        font-style: normal;
        margin-left: 3px;
      }
    }

    .home_profit {
      display: flex;
      justify-content: space-around;
      align-items: center;

      dl {
        width: 49.9%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        dt {
          width: 100%;
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #C9CDD4;
          line-height: 18px;
          text-align: center;
          margin-bottom: 7px;
        }

        dd {
          margin: 0;
          width: 100%;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #C9CDD4;
          line-height: 18px;
          display: flex;
          flex-flow: row;
          justify-content: center;
          align-items: center;

          i {
            font-size: 12px !important;
            height: 14px;
            font-style: normal;
            margin-left: 3px;
          }
        }
      }
    }
  }

  .home_position {
    background: #FFFFFF;

    .position_title {
      height: 36px;
      border-bottom: 1px solid #F2F3F5;
      box-sizing: border-box;
      padding: 0 13px;

      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1D1D;
        line-height: 36px;
        margin-right: 6px;
      }

      i {
        font-style: normal;
        width: 62px;
        height: 16px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A0A3A9;
        line-height: 36px;

      }

    }

    .position_list {
      padding: 17px 41px 35px 19px;

      .position_assets_title {
        height: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A0A3A9;
        position: relative;

        span {
          &:first-child {
            position: absolute;
            top: 0px;
            left: 65px;
          }

          &:last-child {
            position: absolute;
            top: 0px;
            right: 32px;
          }
        }
      }

      ul {
        li {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: flex-start;

          .assets_name {
            width: 50%;
            height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1D1D1D;
            line-height: 18px;
          }

          .assets_money {
            width: 45%;
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;

            p {
              font-size: 13px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #1D1D1D;
              line-height: 18px;
              margin: 0 0 4px 0;
            }

            .position_profit {
              margin-bottom: 16px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              span {
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #A0A3A9;
                line-height: 16px;
                margin-right: 8px;
              }

              i {
                font-style: normal;
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000;
                line-height: 16px;
              }
            }

          }
        }
      }
    }
  }

  .home_explain {
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A0A3A9;
    line-height: 16px;
    text-align: center;
    margin: 40px 0 0 0;
  }
  .butList{
    margin: 10px 0 0 0;
    ul{
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      li{
        border: 1px solid #ccc;
        padding: 4px 6px;
        border-radius: 4px;
        font-size: 14px;
        color: #000000;
      }
    }
  }
  .rate{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    span{
      font-size: 14px;
      color: #656565;
      line-height: 20px;
    }
  }
  .home_conversion {
    box-sizing: border-box;
    padding: 0 20px;

    p {
      font-size: 13px;
      color: #333333;
    }
  }

  .content {
    height: 61px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A0A3A9;
    line-height: 18px;
    padding: 15px 19px;
  }
}
</style>
