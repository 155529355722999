import request from '@/utils/request'

// 获取持仓列表
export function assets(params) {
    return request({
        url: '/api/h5/assets',
        method: 'get',
        params
    })
}
// 获取汇率
export function exchangeRate() {
    return request({
        url: '/api/h5/exchange/rate',
        method: 'get'
    })
}
// 获取资产
export function assetsTotal(params) {
    return request({
        url: '/api/h5/assets/total',
        method: 'get',
        params
    })
}

